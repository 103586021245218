<template>
  <div>
    <b-modal
      ref="modalDataAcesso"
      :title="$t('O que é um imóvel com acessibilidade para pessoas com mobilidade reduzida?')"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="true"
      :ok-title="$t('Fechar')"
      ok-variant="outline-secondary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <p> {{ $t('Para ser considerado um imóvel com acessibilidade para pessoas com mobilidade reduzida o imóvel deve ter no mínimo as seguintes características:') }} </p>

      <p> {{ $t('No interior da habitação:') }} </p>
      <ul>
        <li><b>{{ $t('Portas:') }}</b> {{ $t('a largura das portas deve ser no mínimo de 0,77m de largura;') }}</li>
        <li><b>{{ $t('WC:') }}</b> {{ $t('área útil livre deve ter 1,20m x 0,75m. Pode ter banheira ou poliban, ambos são facilmente adaptáveis para pessoas com mobilidade reduzida.') }}</li>
      </ul>

      <p> {{ $t('Exterior da habitação:') }} </p>
      <ul>
        <li><b>{{ $t('Elevador:') }}</b> {{ $t('a largura da porta deve ter no mínimo 0,77m e a cabine 0,80m de largura e 1,15m de profundidade;') }}</li>
        <li><b>{{ $t('Rampas de acesso:') }}</b> {{ $t('independentemente da inclinação ser mais ou menos correcta do que ditam as normas, o importante é que o imóvel tenha forma de acesso directo até à entrada da habitação.') }}</li>
      </ul>

      <b-form-text>
        <b-link
          href="http://www.inr.pt/uploads/docs/acessibilidade/GuiaAcessEmobi.pdf"
          target="_blank"
          class="font-weight-bold"
        >
          {{ $t('Mais informação') }}
        </b-link>
      </b-form-text>

    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BFormText, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BModal,
    BFormText,
    BLink,
  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  data() {
    return {
    }
  },
  computed: {
    isDark() {
      return this.skin.value === 'dark'
    },
  },
  methods: {
    showModal() {
      return new Promise((resolve, reject) => {
        this.$refs.modalDataAcesso.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    hideModal() {
      this.$refs.modalDataAcesso.hide()
    },
  },
  setup() {
    const { skin } = useAppConfig()

    return {
      skin,
    }
  },
}
</script>
